import { React, useState } from "react"
import { css } from "@emotion/core"
import colors from "./colors"

const ContactForm = props => {
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [name, setName] = useState("")

  return (
    <article
      css={css`
        input,
        textarea {
          background: white;
          padding: 1em;
          font-size: 1em;
          border: 1px lightgray solid;
          width: 100%;
          box-sizing: border-box;
          margin-bottom: 0.5em;
        }

        label {
          font-weight: bold;
          display: block;
        }

        button {
          background: ${colors.gold};
          color: ${colors.red};
          font-weight: 900;
          font-size: 1em;
          padding: 1em;
          border: none;
          border-radius: 10px;
          min-width: 20ch;
          margin-left: calc(100% - 20ch);
          display: block;
          cursor: pointer;
        }

        button:hover {
          background: ${colors.yellow};
        }
      `}
    >
      <form
        action="https://getform.io/f/1de178d4-7f88-48ec-ba66-bd1263af2cc3"
        method="post"
      >
        <label htmlFor="name">Your Name</label>
        <input
          name="name"
          id="name"
          placeholder="Charlie Chaplin"
          type="text"
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <label htmlFor="email">Email Address</label>
        <input
          name="email"
          id="email"
          placeholder="person@example.com"
          type="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <label htmlFor="message">Message</label>
        <textarea
          rows="8"
          cols="60"
          name="message"
          id="message"
          placeholder="Yes, maestro?"
          type="textarea"
          value={message}
          onChange={e => setMessage(e.target.value)}
        />
        <button type="submit">SUBMIT</button>
      </form>
    </article>
  )
}

export default ContactForm

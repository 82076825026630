import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/docatra/dingusgames/src/components/layout.js";
import ContactForm from "../components/contactForm";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Need Something? Get in Touch!`}</h1>
    <hr />
    <p>{`Looking for rules clarifications? Check us out at `}<a parentName="p" {...{
        "href": "https://www.boardgamegeek.com"
      }}><strong parentName="a">{`Boardgame Geek.`}</strong></a></p>
    <p>{`Looking to add your store to our list of retailers? `}<strong parentName="p">{`Submit a form here.`}</strong></p>
    <p>{`Neither of those things? We're happy to answer anyquestions you might have. Contact us using the form below:`}</p>
    <ContactForm mdxType="ContactForm" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      